import React from "react";
import { ToastContainer, toast } from "react-toastify";


export const charValidate = (text : string, limit : number) => {
    if (text.length > limit) return `${text.substring(0, limit-3)}...`
    else return `${text.substring(0, limit)}`
}

interface ItoastConfigOptions {
    position: "top-right" | "top-center" | "top-left" | "bottom-right" | "bottom-center" | "bottom-left" ;
    autoClose: number;
    hideProgressBar: boolean;
    closeOnClick: boolean;
    pauseOnHover: boolean;
    draggable: boolean;
    progress: undefined;
  }

const CONFIG_OPTIONS  : ItoastConfigOptions =  {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}

export const showInfoToast = (toastMessage : string) => {
    toast.info(toastMessage, CONFIG_OPTIONS)
};

export const showSuccessToast = (toastMessage : string) => {
    toast.success(toastMessage, CONFIG_OPTIONS)
};

export const showWarnToast = (toastMessage : string) => {
    toast.warn(toastMessage, CONFIG_OPTIONS)
};

export const showErrorToast = (toastMessage : string) => {
    toast.error(toastMessage, CONFIG_OPTIONS)
};

export const ToastMessage = () => {
    return <ToastContainer />
}